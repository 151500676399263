<template>
  <div class="text-center">
    <!-- <v-btn
      :disabled="dialog"
      :loading="dialog"
      class="white--text"
      color="identity"
      @click="dialog = true"
    >
      Enviando
    </v-btn> -->
    <v-dialog
      v-model="dialog"
      :hide-overlay="hideOverlay"
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          Por favor espera
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: true
    }
  },

  props: {
    hideOverlay: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  watch: {
    dialog(val) {
      if (!val) return

      // setTimeout(() => (this.dialog = false), 4000)
    }
  }
}
</script>
