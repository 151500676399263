import {
  canCheckoutJustAfterCheckin,
  isUnderPeriodToCheckin
} from '@/components/requests/periodsControl'
import { currentPeriodAsId, previousPeriodAsId } from '@/micro/time/date'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { checkActiveRequestByOrg } from '../requestsApi/fbReadRequestsApi'

async function passDataByPeriod(path, code, db) {
  let passRef = db.collection(path)
  let query = passRef.where('code', '==', Number(code)).limit(1)
  const res = await query.get()
  return res
}

export async function sendCheck(code, context, store) {
  const db = firebase.firestore()
  let pathId = currentPeriodAsId()
  let path = getAttendantPassPath(context, pathId)
  let result = await passDataByPeriod(path, code, db)
  if (result.empty) {
    pathId = previousPeriodAsId()
    path = getAttendantPassPath(context, pathId)
    result = await passDataByPeriod(path, code, db)
  }

  if (!hasAppointment(result)) {
    const withoutAppointment = {
      successful: false,
      type: 'unknown',
      name: 'Desconocido',
      quality: 'excpetion'
    }
    return withoutAppointment
  } else {
    // CHECKIN / CHECKOUT
    const doc = result.docs[0]
    const passData = doc.data()
    const passRef = db.doc(`${path}/${doc.id}`)
    const request = await checkActiveRequestByOrg(context, passData.reqId)
    let response = {
      successful: true,
      name: request.party.name
    }
    let action = 'approve'
    let comment = ''
    let status = {}
    try {
      if (isReadyToCheckout(passData)) {
        // CHECKOUT
        // if (multiPass(passData)) {
        //   await passRef.update() to remove the checkin
        // } else {
        //   await passRef.delete()
        // }
        await passRef.delete()
        const quality = isExceptionalCheckout(passData)
          ? 'exception'
          : 'expected'

        status = { type: 'checkout', quality }
      } else if (isReadyToCheckin(passData)) {
        // CHECKIN
        await passRef.update({
          checkin: firebase.firestore.FieldValue.serverTimestamp()
        })
        status = { type: 'checkin', quality: 'expected' }
      } else {
        // UNEXPECTED
        action = 'reject'
        if (wasCheckinIntent(passData)) {
          comment = 'Llegó muy temprano o demasiado tarde'
        } else {
          comment =
            'Situación no considerada. Pedir al Jefe de Seguridad que autorice su salida'
        }
      }
      response = { ...response, ...status }
      await store.dispatch('requests/sendCheckStage', {
        item: request,
        action,
        comment
      })
      return response
    } catch (error) {
      console.log('Can not update the check action', error)
    }
  }
}

export function getAttendantPassPath({ ou }, did) {
  return `pass/${ou}/${did}`
}

function hasAppointment(result) {
  return !result.empty
}

function isReadyToCheckout(passData) {
  return passData.checkin
}

function isReadyToCheckin(passData) {
  return !passData.checkin && isUnderPeriodToCheckin(passData.schedule_start)
}

function isExceptionalCheckout(passData) {
  return !!passData.checkin && canCheckoutJustAfterCheckin()
}

function wasCheckinIntent(passData) {
  return !passData.checkin
}
