import firebase from 'firebase/app'
import 'firebase/firestore'
import { formattedIsoDateTime } from '@/micro/time/date'
import { getQueryPath } from './apiControl'
// import { subHours } from 'date-fns'
// import { graceDurationToCheckin } from '@/cfg'

// export async function getActiveAccessRequestsByArea(rs, ou, aid, uid, store) {
//   const path = getQueryPath(rs, ou, aid, 'covid19pass', 'active')
//   return getMyAccessRequests(path, uid, 100, store)
// }

/**
 * By User | User logged in
 */
export function getMyActiveAccessRequests(rs, ou, uid, store) {
  const path = getQueryPath(rs, ou, 'any', 'covid19pass', 'active')
  return getMyAccessRequests(path, uid, 100, store)
}

/**
 * Resolves the auths depending of the user roles and request conditions
 */
export function getActiveAccessAuthsByContext(rs, ou, area, roles, store) {
  if (isChiefOfArea(roles)) {
    return getActiveAccessAuthsByArea(rs, ou, area, store)
  } else if (isHeadOfSecurity(roles)) {
    return getActiveAccessAuthsByOrg(rs, ou, store)
  } else if (isConsultant(roles)) {
    return getTodayCheckedInAuthsByOrg(rs, ou, store)
  } else {
    return () => {}
  }
}

function isChiefOfArea(roles) {
  return hasRole('d', roles)
}

function isHeadOfSecurity(roles) {
  return hasRole('h', roles)
}

function isConsultant(roles) {
  return hasRole('f', roles)
}

function hasRole(r, roles) {
  return roles.find(role => role === r) !== undefined
}

/**
 * By Area | Chief of Area
 */
export function getActiveAccessAuthsByArea(rs, ou, area, store) {
  const path = getQueryPath(rs, ou, 'any', 'covid19pass', 'active')
  return getRequestsToAuthByArea(path, area, 100, store)
}

/**
 * By Org | Security supervisor
 */
export function getActiveAccessAuthsByOrg(rs, ou, store) {
  const path = getQueryPath(rs, ou, 'any', 'covid19pass', 'active')
  return getRequestsToAuthByStage(path, 'auth1', 100, store)
}

/**
 * By Stage | Turn to check up
 */
export function getTodayCheckedInAuthsByOrg(rs, ou, store) {
  const path = getQueryPath(rs, ou, 'any', 'covid19pass', 'active')
  return getTodayRequestsToAuthByStage(path, 'checkin', 100, store)
}

/**
 * Get the users' requests
 */
function getMyAccessRequests(path, uid, n, store) {
  const condition = {
    key: 'uid',
    op: '==',
    val: uid
  }
  return getOrderedRequestsBySchedule('request', path, condition, n, store)
}

/**
 * Get the users' requests to auth by org
 */
// function getRequestsToAuth(path, n, store) {
//   const query = firebase
//     .firestore()
//     .collection(path)
//     .orderBy('resource.schedule_start', 'desc')
//     .limit(n)

//   return getAndListenToRequests('auth', query, store)
// }

/**
 * Get the users' requests to auth by area
 */
function getRequestsToAuthByArea(path, area, n, store) {
  const condition = {
    key: 'party.area',
    op: '==',
    val: area
  }
  return getOrderedRequestsBySchedule('auth', path, condition, n, store)
}

/**
 * Get the users' requests to auth by area
 */
// .where(
//   'resource.schedule_start',
//   '>=',
//   subHours(new Date(), graceDurationToCheckin())
// )
function getTodayRequestsToAuthByStage(path, stage, n, store) {
  const query = firebase
    .firestore()
    .collection(path)
    .where('workflow.progress', 'array-contains', stage)
    .orderBy('resource.schedule_start', 'desc')
    .limit(n)

  return getAndListenToRequests('auth', query, store)
}

// .where('resource.schedule_start', '>=', subHours(new Date(), 24))
function getRequestsToAuthByStage(path, stage, n, store) {
  const query = firebase
    .firestore()
    .collection(path)
    .where('workflow.progress', 'array-contains', stage)
    .orderBy('resource.schedule_start', 'desc')
    .limit(n)

  return getAndListenToRequests('auth', query, store)
}

function getOrderedRequestsBySchedule(type, path, condition, n, store) {
  const query = firebase
    .firestore()
    .collection(path)
    .where(condition.key, condition.op, condition.val)
    .orderBy('resource.schedule_start', 'desc')
    .limit(n)

  return getAndListenToRequests(type, query, store)
}

/**
 * Get the requests and set a listener to request and stages changes.
 */
function getAndListenToRequests(type, query, store) {
  store.dispatch('requests/setLoading')
  const unsubscribe = query.onSnapshot(snap => {
    // const source = snap.metadata.hasPendingWrites ? 'Local' : 'Server'
    // console.log('(source, changes)', source, snap.docChanges())
    const requests = snap.docs
    const result =
      requests.length > 0
        ? requests.map(doc => {
            const data = doc.data()
            data.created_at = formattedIsoDateTime(data.created_at.toDate())
            data.workflow.stages = data.workflow.stages.map(stage => {
              stage.effective = formattedIsoDateTime(stage.effective.toDate())
              return stage
            })
            return data
          })
        : []

    store.dispatch('requests/updateRequests', { type, requests: result })
    store.dispatch('requests/setLoaded')
  })
  return unsubscribe
}

export async function checkActiveRequestByOrg({ rs, ou }, reqId) {
  const path = getQueryPath(rs, ou, 'any', 'covid19pass', 'active')
  const ref = firebase
    .firestore()
    .collection(path)
    .doc(reqId)

  const doc = await ref.get()
  return doc.data()
}

// export async function getMyArchivedAccessRequests(rs, ou, aid, uid) {
//   const path = getControlPath(rs, ou, aid, 'covid19pass', 'archived')
//   return await getMyAccessRequests(path, uid, 100)
// }

// export async function getMyArchivedAccessAuths(rs, ou, aid) {
//   const path = getControlPath(rs, ou, aid, 'covid19pass', 'archived')
//   return await getRequestsToAuth(path, 100)
// }
